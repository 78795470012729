import jwt_decode from "jwt-decode"
// retrieves session state saved in a JSON Web token
export const GetSession = () => {
    return {
        UserId: 2,
        UserName: "Admin",
        Tenant: "",
        IsMobile: false
    }
}

export const GetUserId = () => {
    const userId = sessionStorage.getItem("userId");
        if (userId) return userId;
        else return null;
}

export const GetUserName = () => {
    const username = sessionStorage.getItem("userName");
        if (username) return username;
        else return null;
}

export const GetToken = () => {
    const token = sessionStorage.getItem("token");

        if (token) 
        {
            let decodedToken = jwt_decode(token);
            let currentDate = new Date().getTime();
            
            if (decodedToken.exp * 1000 < currentDate) {
                return null;
            } 
            else {                
                return token;
            }

        }
        else return null;
}

export const setUserSession= (userName, token, userId, usersRole) => {
    sessionStorage.setItem("userName", userName);
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("userId", userId);
    sessionStorage.setItem("usersRole", usersRole);
}


export const removeUserSession= (userName, token, userId, usersRole) => {
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("usersRole");
}