import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { toODataString } from '@progress/kendo-data-query';
import configData from "../config.json";
import { GetToken} from './Session';
import { get } from 'jquery';

const DataLoader = props => {
  const dataSource = (props.cloudToken !== undefined && props.cloudToken !== "" ? configData.CLOUD_URL : configData.SERVER_URL) + props.dataSource;
  const dataFilter = props.dataFilter;
  const baseUrl = dataSource + "?";
  const init = {
    method: 'GET',
    accept: 'application/json',
    headers: { "Authorization": "Bearer " + (props.cloudToken !== undefined && props.cloudToken !== "" ? props.cloudToken :GetToken()) }
    };
  const total = React.useRef(0);
  const downloadedData = React.useRef([]);
  const lastSuccess = React.useRef('');
  const pending = React.useRef('');
  const lastParams = React.useRef('');
  const lastSearch = React.useRef('');
  const countPending = React.useRef("");
  const countWaiting = React.useRef("");

    const GetCount = (q, init) => {
        fetch(q, init).then(response => {
            try {
                var res = response.json();
                if (res.length === 0) Promise.reject();
            }
            catch (e) {
                console.log(`Error loading ${props.dataSource}: ${e.message}`);
                Promise.reject();
                countPending.current = false;
            }
            return res;
        }).then(d => {
            setTimeout(() => {
                if (countWaiting.current !== "" && countWaiting.current !== countPending.current) {
                    countPending.current = countWaiting.current;
                    countWaiting.current = "";
                    GetCount(countPending.current, init);
                } else {
                    countPending.current = "";
                    countWaiting.current = "";
                }
            }, 2000);
            total.current = d;
            props.onDataReceived.call(undefined, {
                data: downloadedData.current,
                total: d
            });
        });
    }

    if (GetODataString(props) !== lastSuccess.current || props.params !== lastParams.current || props.search !== lastSearch.current) {
        let q = String(dataSource + "/$count");
        if (props.dataState.filter !== undefined) q += "?" + RemoveTop(GetODataString(props));
        if (dataFilter !== "") {
            if (q.indexOf("$filter") >= 0) {
                q = q.replace("$filter=", "$filter=" + dataFilter + " and ");
            } else {
                q += (q.indexOf("?") > 0 ? "&" : "?") + "$filter=" + dataFilter;
            }
        }
        if (props.params !== "" && props.params !== undefined) q += (q.indexOf("?") > 0 ? "&" : "?") + "prm=" + props.params;
        if (props.search !== "" && props.search !== undefined) q += (q.indexOf("?") > 0 ? "&" : "?") + "search=" + props.search;
        // Sometimes React can hit the count endpoint mercilessly resulting in slow page loading and unnecessary cloud costs
        // This code is designed to throttle that behaviour:
        // If there is no pending operation, save the query in countPending.current and hit the endpoint
        // If there is a pending operation, save the query in countWaiting.current and do nothing. This will be latest request, overriding previous values
        // When the current operation finishes, if countWaiting is set and does not match countPending, do that operation
        if (countPending.current === "") {
            countPending.current = q;
            GetCount(q, init);
        } else {
            countWaiting.current = q;
        }
    }



  const requestDataIfNeeded = () => {
    if (pending.current || (GetODataString(props) === lastSuccess.current && props.params === lastParams.current && props.search === lastSearch.current)) {
      return;
    }
    pending.current = GetODataString(props);
    var q = String(pending.current); 
    if (dataFilter !== ""){
      if (q.indexOf("$filter") >= 0){
        q = q.replace("$filter=", "$filter=" + dataFilter + " and ");
      } else {
        q += "&$filter=" + dataFilter;
      }
    }
    if (props.params !== "" && props.params !== undefined) q += "&prm=" + props.params;
    if (props.search !== "" && props.search !== undefined) q += "&search=" + props.search;
    fetch(baseUrl + q, init).then(response => {
      try{
        var res = response.json();
        if (res.length === 0) Promise.reject();
      }
      catch (e) {
          console.log(`Error loading ${props.dataSource}: ${e.message}`);
          Promise.reject();
          if (props.dataSource !== null && String(props.dataSource).endsWith("transactions")) alert("Error loading data. Please try a smaller date range");
      }
      return res;
    }).then(d => {
      lastSuccess.current = pending.current;
      lastParams.current = props.params;
      lastSearch.current = props.search;
      pending.current = '';
        if (GetODataString(props) === lastSuccess.current) {
        downloadedData.current = d;
        props.onDataReceived.call(undefined, {
          data: d,
          total: total.current
        });
      } else {
        requestDataIfNeeded();
      }
    });
  };
  requestDataIfNeeded();
  return pending.current ? <LoadingPanel /> : null;
};

const LoadingPanel = () => {
  const loadingPanel = <div className="k-loading-mask">
        <span className="k-loading-text">Loading</span>
        <div className="k-loading-image" />
        <div className="k-loading-color" />
      </div>;
  const gridContent = document && document.querySelector('.k-grid-content');
  return gridContent ? ReactDOM.createPortal(loadingPanel, gridContent) : loadingPanel;
};

const RemoveTop = (qs) => {
  // for count we want total number of records, so strip out $top in filter
  let q = qs;
  if (q.indexOf("$top") > 0){
    var p = q.indexOf("$top");
    var q2 = q.substring(p);
    var p2 = q2.indexOf("&");
    if (p2 < 0) p2 = q2.length;
    q2 = q.substring(p - 1, p + p2 + 1);
    q = q.replace(q2, "");
  }
  return q;
}

const GetODataString = (props) => {
  // returns a valid OData string for datastate. Web API doesn't like quotes around dates
  if (props.dataState === undefined) return "";
  let q = toODataString(props.dataState);
  let pat = /'\d{4}-\d{2}-\d{2}T/ig;
  let m = -1;
  while(m = pat.exec(q)){
    q = q.substring(0, m.index) + q.substring(m.index + 1, m.index + 29) + q.substring(m.index + 30);
  }
  return q;
}

export default DataLoader
