import * as React from 'react';
import { Notification } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { useLocation } from "react-router-dom";

const Notifications = (props) => {
    let location = useLocation();
    const msg = location.state === null ? "" : location.state["msg"];
    const [message, setMessage] = React.useState(String(msg).startsWith("Error") ? "" : msg);
    const [errorMessage, setErrorMessage] = React.useState(String(msg).startsWith("Error") ? msg : props.errorMessage);
    if (msg !== "") setTimeout(() => setMessage(""), 3000);

    return ( 
        <div>
        {message && <Fade><Notification type={{ style: "success", icon: true }}><span>{message}</span></Notification></Fade>}       
        {errorMessage && <Notification type={{ style: "error", icon: true }}><span>{errorMessage}</span></Notification>}       
        </div>
    );
}

export default Notifications