import configData from "../config.json";
import { GetSession, GetToken, GetUserId } from './Session';

export const GetOptions = (setOptions) => {
    var url = `${configData.SERVER_URL}apiv7/options?userId=${GetSession().UserId}`;
    const init = {
        method: 'GET',
        accept: 'application/json',
        headers: {"Authorization": "Bearer " + GetToken()}
      }
    fetch(url, init).then(response => response.json()).then(d => { 
        setOptions(d);
    })
}

export const GetPermissions = (required, setPermissions) => {
    var url = `${configData.SERVER_URL}apiv7/options?userId=${GetUserId()}`;
    const init = {
        method: 'GET',
        accept: 'application/json',
        headers: {"Authorization": "Bearer " + GetToken()}
      }
    fetch(url,init).then(response => response.json()).then(d => { 
        let createPermission = required.find(obj => String(obj).startsWith("New")) || "PermissionDoesNotExist";
        let canCreate = d.permissions.find(obj => obj === createPermission) === createPermission;
        let editPermission = required.find(obj => String(obj).startsWith("Edit")) || "PermissionDoesNotExist";
        let canEdit = d.permissions.find(obj => obj === editPermission) === editPermission;
        let deletePermission = required.find(obj => String(obj).startsWith("Delete")) || "PermissionDoesNotExist";
        let canDelete = d.permissions.find(obj => obj === deletePermission) === deletePermission;
        let reportPermission = required.find(obj => String(obj).startsWith("Report") || String(obj).indexOf("Export") >= 0) || "PermissionDoesNotExist";
        let canReport = d.permissions.find(obj => obj === reportPermission) === reportPermission;
        let canPrint = required.find(obj => String(obj).startsWith("Print")) !== undefined;
        let viewPermission  = required.find(obj => String(obj).startsWith("View")) || "PermissionDoesNotExist";
        let canView = d.permissions.find(obj => obj === viewPermission) === viewPermission;
        let approvePermission  = required.find(obj => String(obj).startsWith("Approve")) || "PermissionDoesNotExist";
        let canApprove= d.permissions.find(obj => obj === approvePermission) === approvePermission;
        let perm = { 
            CanCreate: canCreate,
            CanEdit: canEdit, 
            CanDelete: canDelete, 
            CanPrint: canPrint, 
            CanReport: canReport, 
            CanView: canView, 
            CanApprove: canApprove
             };
        required.map((x) => {
            if (d.permissions.find(obj => obj === x) === x) perm[x] = true;
            return null;
        })

        setPermissions(perm);
    })
}

